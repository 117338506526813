// extracted by mini-css-extract-plugin
export const root = "PlasmicFeatures-module--root--1GOzQ";
export const box__kyKyW = "PlasmicFeatures-module--box__kyKyW--1Dnd5";
export const header = "PlasmicFeatures-module--header--1Ld5D";
export const section = "PlasmicFeatures-module--section--AVnp2";
export const box__jsQB = "PlasmicFeatures-module--box__jsQB--v8ljY";
export const box__qWyUj = "PlasmicFeatures-module--box__qWyUj--1JD9s";
export const box__jMq46 = "PlasmicFeatures-module--box__jMq46--16O2S";
export const box__luGmS = "PlasmicFeatures-module--box__luGmS--31cIm";
export const bullet__dn95R = "PlasmicFeatures-module--bullet__dn95R--3V_DC";
export const bullet__oXkBp = "PlasmicFeatures-module--bullet__oXkBp--18d3e";
export const bullet__hflHf = "PlasmicFeatures-module--bullet__hflHf--i0TuM";
export const box__u5Pfj = "PlasmicFeatures-module--box__u5Pfj--3f6az";
export const box__p3En6 = "PlasmicFeatures-module--box__p3En6--1H2VB";
export const box__hmnzI = "PlasmicFeatures-module--box__hmnzI--1b3e-";
export const img__z5Tpg = "PlasmicFeatures-module--img__z5Tpg--1k9jW";
export const img__ynpKo = "PlasmicFeatures-module--img__ynpKo--2s0An";
export const homeCta = "PlasmicFeatures-module--homeCta--3_pJJ";
export const footer = "PlasmicFeatures-module--footer--2Pg4E";